/**
    GENERAL
*/

.notification_container {
    display: block;
    position: fixed;
    top: 5px;
    right: 10px;
    z-index: 10000;
    @media only screen and (max-device-width : 640px) {
        width: 95%;
    }
    //@media only screen and (max-device-width: 768px) {
    @media only screen and (min-device-width: 641px) {
        width: auto;
        min-width:280px;
        max-width:280px;
    }
    .msg_container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 80px;
      height: auto;
      background: $light-blue;
      color: white;
      white-space: pre-line; /*Hack to be able to display multi-line*/
      justify-content: flex-start;
      border-radius: 3px;
      border: 1px solid;
      &:before {
        background: url('../assets/logo.png') no-repeat;
        background-size: 100%;
        display: inline-block;
        width: 70px;
        max-width: 70px;
        min-width: 70px;
        height: 70px;
        content:"";
      }
      .msg {
        flex-direction: column;
        justify-content: space-around;
        .message {
            font-size: small;
        }
      }
    }
    .success {
        border-color: $green;
    }
    .error {
        border-color: $red;
    }
    .info {
        border-color: $yellow;
    }
}

// BTN

.btn-flat {
    border-color: $green;
    color: $light-gray;
    border-radius: 10px;
    &:hover {
        color: $green;
    }
}

// IMG SPINNER
.img_spinner {
    margin-top: 50%;
    position: absolute;
    left: 0;
    right: 0;
}