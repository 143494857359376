/**
    FOOTER
*/

.footer_container {
    .footer {
        bottom: 0;
        width: 100%;
        @media only screen and (max-device-width : 991px) {
            .top_container {
                flex: 1;
                flex-direction: column;
                justify-content: space-around;
                .column {
                    flex-direction: column;
                    a {
                        .label {
                            color: $light-gray;
                            font-size: x-small;
                        }
                    }
                    .label {
                        font-size: small;
                    }
                    .images_container {
                        img {
                            width: 15px;
                            margin: 0 5px;
                        }
                    }
                    .img_label {
                        cursor: pointer;
                        img {
                            width: 15;
                            margin: 0 5px;
                        }
                        .label {
                            color: $light-gray;
                            font-size: x-small;
                            text-decoration: none;
                            &:hover {
                                color: $green;
                            }
                        }
                    }
                    .link {
                        cursor: pointer;
                        text-decoration: none;
                        .label {
                            color: $light-gray;
                            font-size: x-small;
                            &:hover {
                                color: $green;
                            }
                        }
                    }
                    .is_active {
                        display: none;
                    }
                }
            }
        }
        @media only screen and (min-device-width: 992px) {
            .top_container {
                flex: 1;
                flex-direction: row;
                justify-content: space-around;
                .column {
                    flex-direction: column;
                    a {
                        .label {
                            color: $light-gray;
                            font-size: 80%;
                        }
                    }
                    .label {
                        font-size: 80%;
                    }
                    .images_container {
                        img {
                            width: 30;
                            margin: 0 5px;
                        }
                    }
                    .img_label {
                        cursor: pointer;
                        img {
                            width: 30;
                            margin: 0 5px;
                        }
                        .label {
                            color: $light-gray;
                            font-size: 80%;
                            text-decoration: none;
                            &:hover {
                                color: $green;
                            }
                        }
                    }
                    .link {
                        cursor: pointer;
                        text-decoration: none;
                        .label {
                            color: $light-gray;
                            font-size: 80%;
                            &:hover {
                                color: $green;
                            }
                        }
                    }
                    .is_active {
                        display: none;
                    }
                }
            }
        }
    }
    @media only screen and (max-device-width : 991px) {
        .copyright {
            text-align: center;
            justify-content: center;
            background-color: $light-black;
            margin-bottom: 70px;
            .label {
                color: $light-gray;
                font-size: x-small;
            }
        }
    }
    @media only screen and (min-device-width: 992px) {
        .copyright {
            text-align: center;
            justify-content: center;
            background-color: $light-black;
            .label {
                color: $light-gray;
                font-size: 80%;
            }
        }
    }
}
