/**
  Publication
*/

.publications, .container {
	.title {
		margin-top: 2em;
		text-align: center;
		background-color: $light-blue;
		border-radius: 10px;
		padding: 5px;
	}
}

.publication {
	margin-top: 2em;
	text-align: center;
	background-color: $light-blue;
	border-radius: 10px;
	padding: 5px;
	.title {
		margin-top: 1em;
		text-align: center;
		background-color: $dark-blue;
		border-radius: 10px;
		padding: 5px;
	}
	.carousel {
		//justify-content: center;
		.carousel-item {
			justify-content: center;
			.carousel-img {
				@media only screen and (max-device-width : 640px) {
					width: 75%;
				}
				@media only screen and (min-device-width: 641px) {
					width: 50%;
				}
			}
		}
	}
	.details {
		text-align: initial;
		.detail_line {
			font-size: small;
			color: $light-gray;
			img {
				width: 15px;
				height: 15px;
				margin: 5px;
			}
		}
		.detail_detail {
			margin: 10px;
			font-size: small;
			color: $light-gray;
		}
	}
	.publication_status {
		color: $light-gray;
	}
	.mark_as_closed, .report_publication {
		color: $light-gray;
		&:hover {
			color: $green;
		}
		&:focus {
			box-shadow:none !important;
		}
	}
	.share_container {
		.title {
			color: $light-gray;
		}
		.shre_btn {
			margin: 0 5px;
			img {
				width: 30px;
				height: 30px;
			}
			&:focus {
				box-shadow:none !important;
			}
		}
	}
}

.publication_row {
	text-align: center;
	justify-content: space-around;
	color: $light-gray;
	overflow-x: hidden;
	.publication_container {
		position: relative; 
		width: 100%; /* for IE 6 */
		background-color: $light-blue;
		border-radius: 10px;
		@media only screen and (max-device-width : 640px) {
			width: 175px;
			height: 240px;
		}
		@media only screen and (min-device-width: 641px) {
			width: 180px;
			height: 245px;
		}
		cursor: pointer;
		.img {
			min-height: 170px;
			max-height: 170px;
			width: 100%;
			object-fit: cover;
		}
		.views { 
			position: absolute; 
			top: 147px; 
			right: 1px; 
			//width: 100%; 
			span { 
				color: white; 
				/* font: bold 24px/45px Helvetica, Sans-Serif;  */
				letter-spacing: -1px;  
				background: rgb(0, 0, 0); /* fallback color */
				background: rgba(0, 0, 0, 0.4);
				border-radius: 10px;
				padding: 3px;
				.label {
					margin: 2px;
				}
				.icon {
					text-align: right;
				}
			}
		}
		.publication_date {
			position: absolute; 
			top: 147px; 
			left: 1px; 
			//width: 100%; 
		} 
		.publication_date span { 
			color: white;
			letter-spacing: -1px;  
			background: rgb(0, 0, 0);
			background: rgba(0, 0, 0, 0.4);
			border-radius: 10px;
			padding: 3px;
		}
		.figure-caption {
			width: 171px;
			color: $light-gray;
			.label {
				margin-top: 3px;
			}
		}
	}
	.zoom:hover {
		-ms-transform: scale(1.1); /* IE 9 */
		-webkit-transform: scale(1.1); /* Safari 3-8 */
		transform: scale(1.1); 
	}
}

.page-item.active .page-link{
	background-color: $green !important;
	border-color: $green !important;
	color: #0f1724 !important;
}

.page-link {
	color: #0f1724 !important;
}

.Yellow-hover:hover {
	color: #34ebc0;
}

.Fit-content {
	width: fit-content;
}


/**
	FAVORIS
	UPLOAD
*/
.container {
	.title {
		margin-top: 3em;
		text-align: center;
		background-color: $light-blue;
		border-radius: 10px;
		padding: 5px;
	}
	.not_connected_card {
		margin-top: 2em;
		text-align: center;
		background-color: $light-blue;
		border-radius: 10px;
		padding: 5px;
		justify-content: center;
		height: 70vh;
		width: 100%;
		._card {
			margin-top: 100px;
			.img {
				width: 50px;
				height: 50px;
			}
			.label {
				margin-top: 15px;
			}
			.btn-flat {
				align-self: center;
				border-color: $green;
				color: $light-gray;
				border-radius: 10px;
				margin: 30px 0 0 0;
				@media only screen and (max-device-width : 640px) {
					width: 35%;
				}
				@media only screen and (min-device-width: 641px) {
					width: 15%;
				}
				&:hover {
					color: $green;
				}
				&:focus {
					box-shadow:none !important;
				}
			}
		}
	}
	.form_container {
		margin-top: 2em;
		text-align: center;
		background-color: $light-blue;
		border-radius: 10px;
		padding: 5px;
		justify-content: center;
		.form {
			margin: 0 auto;
			justify-content: center;
			margin-top: 5px;
			@media only screen and (max-device-width : 640px) {
				width: 95%;
			}
			@media only screen and (min-device-width: 641px) {
				width: 85%;
			}
			.input_style {
				background-color: $dark-blue;
				color: $light-gray;
				border-color: $green;
				&:focus {
					box-shadow:none !important;
				}
			}
			.custom-file {
				.custom-file-input {
					height: 90px!important;
					&:focus {
						box-shadow:none !important;
					}
				}
				.custom-file-label {
					height: 90px !important;
					background-color: $dark-blue !important;
					border-color: $green !important;
					color: $light-gray;
					height: 80px;
					&::after{
						background-color: $light-blue;
						border-color: $green !important;
						color: $light-gray;
					}
					&:focus {
						box-shadow:none !important;
					}
				}
			}
			.img_preview_container {
				background-color: $dark-blue;
				color: $light-gray;
				border-color: $green;
				text-align: center;
				justify-content: 'center';
				margin-top: 60px;
				.img_preview {
					width: 190px;
					height: 170px;
					//min-width: 190px;
					//min-height: 180px;
					margin: 3px;
				}
				.remove_img_icon {
					position: absolute;
					margin-left: -190px;
					margin-top: 1%;
				}
			}
			.btn-flat {
				align-self: center;
				border-color: $green;
				color: $light-gray;
				border-radius: 10px;
				margin: 30px 0;
				@media only screen and (max-device-width : 640px) {
					width: 70%;
				}
				@media only screen and (min-device-width: 641px) {
					width: 25%;
				}
				&:hover {
					color: $green;
				}
				&:focus {
					box-shadow:none !important;
				}
			}
		}
	}
}

.search_results {
	.title {
		margin-top: 2em;
		text-align: center;
		background-color: $light-blue;
		border-radius: 10px;
		padding: 5px;
	}
	.no_search_results {
		color: $light-gray;
		text-align: center;
	}
}