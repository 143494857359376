/**
    HOME
*/

.home {
    .container {
        @media only screen and (max-device-width : 640px) {
            margin-top: 6em;
        }
        @media only screen and (min-device-width: 641px) {
            margin-top: 8em;
        }
        .introduction {
            text-align: initial;
        }
        .download_app {
            width: fit-content;
            cursor: pointer;
            img {
                margin: 7px;
            }
            .label {
                text-decoration: none;
                color: $light-gray;
                &:hover {
                    color: $green;
                }
            }
            &:hover {
                -ms-transform: scale(1.1); /* IE 9 */
                -webkit-transform: scale(1.1); /* Safari 3-8 */
                transform: scale(1.1); 
            }
        }
    }
    .title {
        margin-top: 2em;
        text-align: center;
        background-color: $light-blue;
        border-radius: 10;
        padding: 5px;
    }
    .categories {
        text-align: center;
        .row {
            .col_card {
                cursor: pointer;
                .label {
                    color: $light-gray;
                }
                &:hover {
                    -ms-transform: scale(1.1); /* IE 9 */
                    -webkit-transform: scale(1.1); /* Safari 3-8 */
                    transform: scale(1.1);
                    .label {
                        color: $green;
                    }
                }
            }
        }
    }
}
