/**
    MESSAGE
*/

.message_component {
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    direction: ltr;
    .title {
        margin-top: 3em;
        text-align: center;
        background-color: $light-blue;
        border-radius: 10px;
        padding: 5px;
    }
    .not_connected_card {
		margin-top: 2em;
		text-align: center;
		background-color: $light-blue;
		border-radius: 10px;
		padding: 5px;
		justify-content: center;
		height: 70vh;
		width: 100%;
		._card {
			margin-top: 100px;
			.img {
				width: 50px;
				height: 50px;
			}
			.label {
				margin-top: 15px;
			}
			.btn-flat {
				align-self: center;
				border-color: $green;
				color: $light-gray;
				border-radius: 10px;
				margin: 30px 0 0 0;
				@media only screen and (max-device-width : 640px) {
					width: 35%;
				}
				@media only screen and (min-device-width: 641px) {
					width: 15%;
				}
				&:hover {
					color: $green;
				}
				&:focus {
					box-shadow:none !important;
				}
			}
		}
    }
    .message_container {
        height: 75%;
        margin-top: 2em;
        text-align: center;
        background-color: $light-blue;
        border-radius: 10px;
        padding: 5px;
        justify-content: center;
        .container {
            display: flex;
            flex-direction: row;
            .users_list_container {
                margin: 1px;
                border-width: 1px !important;
                border-color: $green !important;
                @media only screen and (max-device-width : 991px) {
					width: 100%;
                    border: none;
				}
				@media only screen and (min-device-width: 992px) {
					width: 27%;
                    border: solid;
                }
                .search_input {
                    color: white;
                    background-color: $dark-blue;
                    border-color: $green;
                    opacity: 0.3;
                    cursor: not-allowed;
                    &:focus {
                        box-shadow: none !important;
                    }
                }
            }
            .message_view_container {
                color: white;
                background-color: '';
                border-color: $green;
                flex-direction: column;
                justify-content: space-around;
                border-width: 1px !important;
                border-color: $green !important;
                margin: 1px;
                @media only screen and (max-device-width : 991px) {
					width: 100%;
                    border: none;
				}
				@media only screen and (min-device-width: 992px) {
					width: 73%;
                    border: solid;
                }
                .title_container {
                    margin-top: 5px;
                    .back_btn {
                        margin-right: 40%;
                    }
                    .avatar {
                        width: 40px;
                        height: 40px;
                        margin: 3px 5px;
                    }
                    small {
                        color: $light-gray;
                    }
                }
                .message_list_container {
                    background-color: $dark-blue;
                    color: white;
                }
                .message_input_container {
                    flex-direction: row;
                    display: flex;
                    margin-top: 5px;
                    width: 100%;
                    .input_and_btn {
                        width: 69%;
                        .message_input {
                            margin-right: 1%;
                            margin-left: 0;
                            &:focus {
                                box-shadow: none !important;
                            }
                        }
                    }
                    .btn-flat {
                        border-color: $green;
                        color: $light-gray;
                        width: 30%;
                        text-align: center;
                        background-color: $dark-blue;
                        height: fit-content;
                        margin-left: 1%;
                        &:hover {
                            color: $green;
                        }
                        &:focus {
                            box-shadow:none !important;
                        }
                    }
                }
                .on_init {
                    margin-top: 2em;
                    text-align: center;
                    background-color: $light-blue;
                    border-radius: 10px;
                    padding: 5px;
                    justify-content: center;
                    height: 70vh;
                    width: 100%;
                    ._card {
                        margin-top: 100px;
                        .img {
                            width: 50px;
                            height: 50px;
                        }
                        .label {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
        .no_messages_card {
            margin-top: 2em;
            text-align: center;
            background-color: $light-blue;
            border-radius: 10px;
            padding: 5px;
            justify-content: center;
            height: 70vh;
            width: 100%;
            ._card {
                margin-top: 100px;
                .img {
                    width: 50px;
                    height: 50px;
                }
                .label {
                    margin-top: 15px;
                }
            }
        }
    }
}