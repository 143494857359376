/**
    USER
*/

.profil_container {
    margin-top: 2em;
    justify-content: space-around;
    background-color: $light-blue;
    border-radius: 10px;
    padding: 5px;
    margin-top: 5em;
    .profil_row {
        color: $light-gray;
        align-items: center;
        .profil_col {
            text-align: center;
            img {
                width: 50px;
                height: 50px;
                &:hover {
                    border: 1px solid $green;
                    cursor: pointer;
                }
            }
            small {
                &:hover {
                    color: $green;
                    cursor: pointer;
                }
            }
            .btn-flat {
                border-color: $green;
                color: $light-gray;
                border-radius: 10px;
                &:hover {
                    color: $green;
                }
                &:focus {
                    box-shadow:none !important;
                }
            }
        }
        @media only screen and (max-device-width : 640px) {
            .bigger {
                flex: 3;
            }
        }
        //@media only screen and (max-device-width: 768px) {
        @media only screen and (min-device-width: 641px) {
            .bigger {
                flex: 1;
            }
        }
    }
}

.profil_picture_modal {
    background-color: $light-blue;
    h3 {
        text-align: center;
        background-color: $dark-blue;
        border-radius: 10px;
        padding: 5px;
    }
    .container {
        .img_preview_container {
            background-color: $dark-blue;
            color: $light-gray;
            border-color: $green;
            text-align: center;
            justify-content: center;
            margin-top: 60px;
            margin-bottom: 10px;
            .img_preview {
                min-width: 50px;
                min-height: 50px;
                margin: 3px;
                width: 50px;
            }
        }
        .btn_container {
            text-align: center;
            .btn-flat {
                border-color: $green;
                color: $light-gray;
                border-radius: 10px;
                &:hover {
                    color: $green;
                }
                &:focus {
                    box-shadow:none !important;
                }
            }
        }
    }
}

// login
.account_container, .user_action_modal {
    background-color: $light-blue;
    .title {
        text-align: center;
        background-color: $dark-blue;
        border-radius: 10px;
        padding: 5px;
    }
    .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media only screen and (max-device-width : 640px) {
            width: 100%;
        }
        @media only screen and (min-device-width: 641px) {
            width: 75%;
        }
        form {
            .form-group {
                .input {
                    &:focus {
                        box-shadow:none !important;
                    }
                }
            }
        }
        .btn-flat {
            align-self: center;
            border-color: $green;
            color: $light-gray;
            border-radius: 10px;
            width: 75%;
            margin: 10px 0;
            &:hover {
                color: $green;
            }
            &:focus {
                box-shadow:none !important;
            }
        }
        .bottom_link {
            text-align: center;
            color: $light-gray;
            margin: 5px;
            cursor: pointer;
            width: fit-content;
            align-self: center;
            &:hover {
                color: $green;
            }
        }
        hr {
            width: 96%;
        }
        .facebook_login {
            border-color: #3B5998;
            &:before {
                content: url('../assets/facebook-logo.svg');
                margin: 5px 0;
            }
            a {
                text-decoration: none;
                color: $light-gray;
                &:hover {
                    color: $green;
                }
            }
        }
    }
}

// external
.authenticate {
    .loading {
        width: 30%;
        text-align: center;
        margin: 30% auto;
        color: #fff;
        padding: 20px;
        border-radius: 3px;
        background: $light-blue;
    }
}