/**
    NAVIGATION
*/

.navigation {
    color: $green !important;
    .container {
        flex: 1;
        flex-direction: row;
        justify-content: space-around !important;
        .container {
            flex-direction: column;
            .label {
                color: $light-gray;
                overflow: hidden;
                @media only screen and (max-device-width : 370px) {
                    display: none;
                }
                @media only screen and (min-device-width : 371px) {
                    font-size: x-small;
                }
            }
        }
    }
    .navbar_items {
        .navbar-nav {
            background-color: $light-blue;
            color: #e3eefd;
            .nav-link {
                .navbar_element {
                    width: initial;
                    border-radius: 5em;
                    padding: 5px;
                }
            }
        }
    }
}
.navigation_mobile {
    justify-content: center;
}
