//COLORS
$blue: #0469af;
$red: #a3213d;
$green: #34ebc0;
$yellow: #F9BA48;
$gray: #95989A;
$brown: #545050;

$light-gray: #B6C6D0;
$lighter-gray: #F1F3F4;
$dark-gray: #425B8A;

$dark-green: #00796B;
$light-green: #BCF5EA;

$dark-blue: #0f1724;
$light-blue: #1d2636;
$light-gray: #92ABCF;
$light-black: #18212e;

$patina: #618C87;

$bg: #f0f0f0;

$dark: #333333;
$border-gray:#cdcdcd;

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background:white;}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3); 
  border-radius: 10px;
} 
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.5);
  background: $red;
}

// IMPORTS
@import'src/style/home';
@import'src/style/publication';
@import'src/style/user';
@import'src/style/general';
@import'src/style/navigation';
@import'src/style/message';
@import'src/style/footer';
//

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #92abcf;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #1d2636;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3); 
  border-radius: 10px;
} 
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.5);
  background: #34ebc0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Nav-bar {
  background-color: #1d2636;
  color: #e3eefd;
}

.Nav-bar-element {
  border: 1px solid #34ebc0;
  color: #92abcf;
  width: 25%;
}

.inactive {
  color: #fff;
  text-decoration: none;
}

.active {
  color: gray;
  text-decoration: none;
}

.Nav-bar-element:hover{
  color: #34ebc0;
}

.Search-element {
  width: 44%;
}


.Bottom-nav {
  border: 1px solid;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}

.Body-container {
  margin-top: 4rem!important;
  margin-bottom: 4rem!important;
  min-height: 100vh;
}

h1 {
  font-family: 'Titillium web','Fondamento', cursive;
  margin-bottom: 4rem!important;
  color: #34ebc0;
}

h3 {
  font-family: 'Titillium web','Fondamento', cursive;
  margin-bottom: 1rem!important;
  color: #34ebc0;
}

p {
  color: #92abcf;
}

.Category:hover{
  color: #34ebc0;
}

.Publication-img { 
  position: relative; 
  width: 100%; /* for IE 6 */
}

.Img-text { 
  position: absolute; 
  top: 157px; 
  left: 63px; 
  width: 100%; 
}

.Publication-img-preview {
  position: absolute;
  margin-left: -171px;
  margin-top: 1%;
}

.Img-text span { 
  color: white; 
  /* font: bold 24px/45px Helvetica, Sans-Serif;  */
  letter-spacing: -1px;  
  background: rgb(0, 0, 0); /* fallback color */
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 3px;
}

.Date-publication {
  position: absolute; 
  top: 157px; 
  left: -40px; 
  width: 100%; 
}

.Date-publication span { 
  color: white;
  letter-spacing: -1px;  
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 3px;
}

.zoom:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1); 
}

.Publications-img {
  min-height: 180px;
  max-height: 180px;
}

.Image-preview {
  min-width: 171px;
  min-height: 180px;
  margin: 3px;
}

.Profil-mage-preview {
  min-width: 42px;
  min-height: 42px;
  margin: 3px;
}

.rce-mlist {
  height: 55vh !important;
}

.rce-mbox {
  background-color: #18a3b5 !important;
}

.rce-mbox-left-notch {
  fill: #18a3b5 !important ;
}

.rce-mbox-right {
  background-color: #558759 !important;
}

.rce-mbox-right-notch {
  fill: #558759 !important ;
}

.chat-list {
  /* background-color: red !important; */
}

.rce-citem-body--top-title {
  text-align: left;
  font-size: small !important;
}

.rce-citem-body--top-time {
  font-size: x-small !important;
  color: #92abcf !important;
}

.rce-citem-body--bottom-title {
  font-size: x-small !important;
  color: #92abcf !important;
  text-align: left;
}

.rce-citem {
  background-color: #0f1724 !important;
  color: #92abcf !important;
}

.rce-citem-body {
  border-color: #34ebc0 !important
}

.text-green {
  color: $green;
}